import React, {Component} from 'react';
import {connect} from 'react-redux';
import Post from '../../components/Post/Post';
import {loadPostsPage} from '../../services/PostsService';
import Button from '@material-ui/core/Button';
import './Posts.scss';
import {Hidden} from '@material-ui/core';
import EventNoteIcon from '@material-ui/icons/EventNote';
import {printPublishDate} from '../../utils/StringUtils';
import {getFirstLevelDomain} from '../../utils/CommonUtils';

class Posts extends Component {

  initialState = {
    page: {last: true}
  };

  state = {
    ...this.initialState,
    root: `//${getFirstLevelDomain()}`
  };

  componentDidMount() {
    this.setState(this.state);
    this.loadInitialPosts();
  };

  calculateVideoHeightStyle = () => {
    const width = document.getElementById('posts-wrapper').clientWidth;
    return {
      height: (width * 9 / 16) + 'px'
    };
  };

  loadInitialPosts = () => {
    const preloaded = window['posts'];
    if (preloaded) {
      this.setState({page: preloaded});
      return;
    }
    loadPostsPage().then(data => {
      this.setState({page: data});
    });
  };

  handleLoadMore = () => {
    const nextPageNumber = this.state.page.number + 1;
    loadPostsPage(nextPageNumber).then(data => {
      data.content = [...this.state.page.content, ...data.content];
      this.setState({page: data});
    });
  };

  render() {
    const t = this.props.t;
    return (
      <>
        <div className="posts-wrapper" id="posts-wrapper">
          {this.state.page.content ?
            <>
              <Hidden smDown>
                <div className='posts-timeline'/>
                {this.state.page.content.map(post =>
                  <div key={post.id} className='post-timeline-event'>
                    <div className='post-timeline-icon'>
                      <div className='post-timeline-icon-wrapper'>
                        <EventNoteIcon/>
                      </div>
                    </div>
                    <div className='post-date-wrapper'>
                      <div className='post-head-date'>
                        <div className='post-head-date-text'>{printPublishDate(post)}</div>
                      </div>
                    </div>
                    <div className='post-timeline-body'>
                      <Post dto={post} root={this.state.root} key={post.id}
                            videoHeightStyle={this.calculateVideoHeightStyle()} minifyEnable={true}/>
                    </div>
                  </div>
                )}
              </Hidden>
              <Hidden mdUp>
                {this.state.page.content.map(post =>
                  <Post dto={post} root={this.state.root} key={post.id}
                        videoHeightStyle={this.calculateVideoHeightStyle()} minifyEnable={true}/>)}
              </Hidden>
            </>
            : ''}
        </div>
        {!this.state.page.last ?
          <div className="load-next">
            <Button className="load-next-button" variant="contained" color="primary"
                    onClick={this.handleLoadMore}>{t('loadMore')}</Button>
          </div>
          : ''}</>
    );
  }
}

const mapStateToProps = state => {
  return {t: state.i18n.translate};
};

const mapActionsToProps = () => ({});

export default connect(mapStateToProps, mapActionsToProps)(Posts);