import React, {Component} from 'react';
import {loadAboutPage} from '../../services/BioService';

class About extends Component {

  state = {
    content: null
  };

  componentDidMount() {
    loadAboutPage().then(data => this.setState({content: data}));
  }

  render() {
    return (
      <div dangerouslySetInnerHTML={{__html: this.state.content?.body}}/>);
  }
}


export default About;