import React, {Component} from 'react';
import './Error.scss';
import {connect} from 'react-redux';

class Error extends Component {

  state = {
    code: []
  };

  componentDidMount() {
    this.setState({code: this.props.code.split('')});
  }

  render() {
    return (
      <div className="container">
        <div className="gears">
          <div className="table-group">
            {this.state.code && this.state.code.map(num => {
              if (num !== '0') {
                return <div className="table-col error-text">{num}</div>;
              } else {
                return <>
                  <div className="table-col">
                    <div className="gear two">
                      <div className="bar"/>
                      <div className="bar"/>
                      <div className="bar"/>
                    </div>
                  </div>
                </>;
              }
            })}
          </div>
          <div className="text-container">
            <h2>{this.props.t(`errorMessage.${this.props.code}`)}</h2>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {t: state.i18n.translate};
};

export default connect(mapStateToProps)(Error);
