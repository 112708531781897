import {
  COMMON_CONFIG_LOAD,
  COMMON_CONFIG_SET,
  COMMON_DIALOG_CLOSE,
  COMMON_DIALOG_OPEN,
  COMMON_TOAST_SHOW,
  dialogDefaultAction
} from './CommonConstants';


const initialState = {
  dialogOpen: false,
  toastStack: [],
  dialogCallback: () => null,
  dialogData: {
    title: null,
    content: null,
    actions: [dialogDefaultAction],
    options: {
      overridable: true,
      override: false,
      // list of props which should be concatenated with a space when currently opened dialog and should be shown
      // toast. If this is an empty array dialog will be put to the stack or follow override instructions
      fallWithProps: []
    }
  },
  dialogStack: []
};

export const CommonReducer = (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case COMMON_DIALOG_CLOSE:
      if (state.dialogStack.length) {
        const data = state.dialogStack[0];
        return {...state, dialogData: data, dialogStack: state.dialogStack.slice(1)};
      }
      return {...state, dialogOpen: false};

    case COMMON_DIALOG_OPEN:
      if (!state.dialogOpen
        || (state.dialogData.options.overridable // case if dialog can't be overridden
          && payload.options && payload.options.override)) { // case if should be overridden
        return {...state, dialogOpen: true, dialogData: payload};
      } else {
        const stack = Object.assign([payload], state.dialogStack);
        return {...state, dialogStack: stack};
      }

    case COMMON_TOAST_SHOW:
      return state;

    case COMMON_CONFIG_LOAD:
      return state;

    case COMMON_CONFIG_SET:
      return {...state, ...payload};

    default:
      return state;
  }
};