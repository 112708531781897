import React, {Component} from 'react';
import './Post.scss';
import Paper from '@material-ui/core/Paper';
import {connect} from 'react-redux';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@material-ui/icons/KeyboardArrowUpRounded';
import {MonetizationOn} from '@material-ui/icons';
import ShareIcon from '@material-ui/icons/Share';
import Grid from '@material-ui/core/Grid';
import Item from '../Item/Item';
import {printPublishDate} from '../../utils/StringUtils';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import {Fab, Hidden} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {findUserById, getUserDomain, isSubdomain} from '../../utils/CommonUtils';

class Post extends Component {

  _bodyRef = null;

  state = {
    _bodyRef: null,
    minifyEnabled: false,
    minified: false
  };

  buildUsername = (dto, isEn) => {
    const user = findUserById(dto.ownerId);
    return isEn ? `${user.nameEn} ${user.lastEn}` : `${user.nameRu} ${user.lastRu}`;
  };

  componentDidMount() {
    const isImages = this.props.dto.items.find(item => item.type === 'IMAGE' || item.type === 'VIDEO');
    if (this.props.minifyEnable && (this._bodyRef?.clientHeight > 400 || isImages)) {
      this.setState({minifyEnable: true, minified: true});
    }
  }

  componentWillUnmount() {
    this.setBodyRef(null);
  }

  handlePostHeight = () => {
    const nextState = !this.state.minified;
    this.setState({minified: nextState});
    if (nextState) {
      this._bodyRef.scrollIntoView();
      const currentScrollPos = window.scrollY || window.scrollTop || document.getElementsByTagName('html')[0].scrollTop;
      window.scroll(0, currentScrollPos > 120 ? currentScrollPos - 120 : 0);
    }
  };

  setBodyRef = block => this._bodyRef = block;

  getBodyClassList = () => 'post-body' + (this.state.minifyEnable && this.state.minified ? ' post-body-minified' : '');

  getPostUrl = (dto) => {
    return !isSubdomain() ? `/${getUserDomain(dto.ownerId)}/${dto.url}` : `/${dto.url}`;
  };

  render() {
    const dto = this.props.dto;
    const username = this.buildUsername(dto, this.props.isEn);
    return (
      <Paper className="post-block">
        <Grid sm={12} xs={12} className="post-head">
          <Grid sm={8} xs={8} className="post-head-title">
            <h3>
              {dto.name}
              <Hidden only='xs'>
                {dto.commercial ? <MonetizationOn className="coin-icon"/> : ''}
              </Hidden>
            </h3>
          </Grid>
          <Grid sm={4} xs={4} className="post-head-username">
            {username}
          </Grid>
        </Grid>

        <Grid container xs={12} sm={12} className="post-date-hidden">
          <Grid xs={6} sm={6}>{printPublishDate(dto)}</Grid>
          <Grid xs={6} sm={6} className="post-date-coin">
            {dto.commercial ? <MonetizationOn className="coin-icon"/> : ''}
          </Grid>
        </Grid>

        <div className={this.getBodyClassList()} id={`post-${dto.id}`} ref={this.setBodyRef}>
          {dto.items.map(item => (
            <Item dto={item} root={this.props.root} post={dto.name} key={item.id}
                  videoHeightStyle={this.props.videoHeightStyle}/>))}
        </div>

        <Grid className="post-footer" container sm={12}>
          <Grid item sm={4} xs={4}>
            <Link className="post-link-to-page" to={{pathname: this.getPostUrl(dto), query: {dto: dto}}}>
              <OpenInNewIcon/>
            </Link>
          </Grid>
          <Grid className="less-more-button" onClick={this.handlePostHeight} item sm={4} xs={4}>
            {this.state.minifyEnable &&
            <Fab size="small" aria-label="add" className="footer-fab">
              {this.state.minified ? <KeyboardArrowDownRoundedIcon/> : <KeyboardArrowUpRoundedIcon/>}
            </Fab>}
          </Grid>
          <Grid className="share-icon" item sm={4} xs={4}>
            <ShareIcon/>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

const mapStateToProps = state => {
  return {
    isRu: state.i18n.isRu,
    isEn: state.i18n.isEn,
    users: state.common.users,
    translate: state.i18n.translate
  };
};

export default connect(mapStateToProps)(Post);
