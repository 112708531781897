import {LANGUAGE_CHANGE, LANGUAGE_LOADED} from './Lang';
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/en-gb';

const translator = (context) => {
  return function translate(key, args = []) {
    if (!key) return '';
    const parts = key && key.split('.');
    let property = context;
    parts.forEach(pt => property = property[pt] ? property[pt] : '');

    Array.isArray(args) && args.forEach((arg, index) => {
      property = property.split(`%${index}`).join(arg);
    });

    return property;
  };
};

const initialState = {
  current: null,
  requested: null,
  initialized: false,
  keys: {},
  translate: () => '...'
};

export const I18nReducer = (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case LANGUAGE_CHANGE:
      return {...state, requested: payload};
    case LANGUAGE_LOADED:
      delete state.translate;
      const requested = state.requested ?? payload.requested;
      moment.locale(requested?.key);
      return {
        ...state,
        translate: translator(payload.keys),
        initialized: true,
        keys: payload.keys,
        current: requested,
        isRu: requested.key === 'ru',
        isEn: requested.key === 'en'
      };
    default:
      return state;
  }
};
