import React, {Component} from 'react';
import './Item.scss';

class Item extends Component {

  onImageError = (e) => {
    if (!e.target.src.match(/no_image.png/)) {
      e.target.onerror = null;
      e.target.src = '/img/no_image.png';
    }
  };

  printItem = () => {
    const dto = this.props.dto;
    const post = this.props.post;
    switch (dto.type) {
      case 'TEXT':
        return (<p>{dto.body}</p>);
      case 'IMAGE':
        return (<img src={`${this.props.root}/files/items/${dto.id}.jpg`} className={dto.body} alt={post}
                     onError={this.onImageError}/>);
      case 'VIDEO':
        return <div className="post-item-video" style={this.props.videoHeightStyle}
                    dangerouslySetInnerHTML={{__html: dto.body}}/>;
      case 'CODE':
        return <div className="post-item-code" dangerouslySetInnerHTML={{__html: dto.body}}/>;
      default:
        return '';
    }
  };

  render() {
    return (
      <div className="post-item">
        {this.printItem()}
      </div>
    );
  }
}

export default Item;
