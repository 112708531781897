export const COMMON_DIALOG_OPEN = 'COMMON_DIALOG_OPEN';
export const COMMON_DIALOG_CLOSE = 'COMMON_DIALOG_CLOSE';

export const COMMON_TOAST_SHOW = 'COMMON_TOAST_SHOW';
export const COMMON_TOAST_HIDE = 'COMMON_TOAST_HIDE';

export const dialogDefaultAction = {
  name: 'OK',
  close: true,
  action: () => null
};

export const COMMON_CONFIG_LOAD = 'COMMON_CONFIG_LOAD';
export const COMMON_CONFIG_SET = 'COMMON_CONFIG_SET';
