import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import Posts from '../pages/Posts/Posts';
import './Route.scss';
import About from '../pages/About/About';
import Bio from '../pages/Bio/Bio';
import SinglePost from '../pages/Post/SinglePost';
import Error from '../components/Error/Error';

export const PostsExclusions = ['about'];

export default () => {
  return (
    <div className="main-layout">
      <Switch>
        <Route exact path="/" component={Posts}/>
        <Route exact path="/about" component={About}/>
        <Route exact path="/bio" component={Bio}/>
        <Route path="/404" render={(props) => <Error code="404" {...props} />}/>
        <Route exact path="/:username?/:url" component={SinglePost}/>
        <Redirect to="/404"/>
      </Switch>
    </div>
  );
}
