import {toData} from '../utils/ResponseUtils';
import {load} from './BaseService';

export const loadPostsPage = (num = 0, size = 10) => {
  return load(`/api/post?page=${num}&size=${size}`).then(toData);
};

export const loadPostByUrl = (owner = '', url = '') => {
  return load(`/api/post/${owner ? owner + '/' : ''}${url}`).then(toData);
};

