import {applyMiddleware, combineReducers, createStore} from 'redux';
import thunkMiddleware from 'redux-thunk';
import {CommonReducer} from './common/CommonReducer';
import {I18nReducer} from './i18n/I18nReducer';

export const store = createStore(combineReducers({
    common: CommonReducer,
    i18n: I18nReducer
  }),
  applyMiddleware(
    thunkMiddleware
  )
);
