import {toData} from '../utils/ResponseUtils';
import {load} from './BaseService';

export const loadAboutPage = () => {
  return load(`/api/bio/about`).then(toData);
};

export const loadBiography = () => {
  return load(`/api/bio`).then(toData);
};
