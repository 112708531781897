import React, {Component} from 'react';
import {loadPostByUrl} from '../../services/PostsService';
import Post from '../../components/Post/Post';
import './SinglePost.scss';
import {getFirstLevelDomain} from '../../utils/CommonUtils';
import Error from '../../components/Error/Error';

class SinglePost extends Component {

  state = {
    post: '',
    error: false,
    root: `//${getFirstLevelDomain()}`
  };

  isValidRequestedUrl = (username, url) => !!window['urls'] || window['urls']?.[username]?.indexOf(url) !== -1;

  componentDidMount() {
    let param = this.props.location.query;
    if (param) {
      this.setState({post: param.dto});
    } else {
      const params = this.props.match?.params;
      let postUrl = params?.url;
      let owner = params?.username ?? '';
      this.loadPost(owner, postUrl);
    }
  }

  loadPost = (ownerId, id) => {
    if (!this.isValidRequestedUrl(ownerId, id)) {
      this.setState({error: true});
      return;
    }
    const preloaded = window['posts'];
    if (preloaded) {
      this.setState({post: preloaded});
      return;
    }
    loadPostByUrl(ownerId, id).then(data => {
      this.setState({post: data});
    }).catch(() => {
      this.setState({error: true});
    });
  };

  calculateVideoHeightStyle = () => {
    const width = document.getElementById('post-wrapper').clientWidth;
    return {
      height: (width * 9 / 16) + 'px'
    };
  };

  render() {
    return (
      <div className="post-wrapper" id="post-wrapper">
        {this.state.post &&
        <Post dto={this.state.post} root={this.state.root} key={this.state.post.id}
              videoHeightStyle={this.calculateVideoHeightStyle()}
              minifyEnable={false}/>}
        {this.state.error && <Error code={'404'}/>}
      </div>);
  }
}

export default SinglePost;