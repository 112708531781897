import {getFirstLevelDomain, isLocalhost} from './CommonUtils';
import moment from 'moment';

export function isEmail(string) {
  return !!string.match(/@/);
}

export function toDomainUrl(domain, path) {
  return isLocalhost()
    ? `${optionalInsert(domain, '://')}${domain}${path}`
    : `${window.location.protocol}//${domain}${optionalInsert(domain, '.')}${getFirstLevelDomain()}${path}`;
}

export function optionalInsert(condition, value) {
  return condition ? value : '';
}

export function insertLeadingZeros(num, size) {
  let result = '' + num;
  while (num.length < size) {
    result = '0' + result;
  }
  return result;
}

export function printPublishDate(dto) {
  const month = printPublishMonth(dto);
  const day = printPublishDay(dto);
  return moment(`${dto.year}${month.create}${day.create}`).format(`${day.format}${month.format}YYYY`);
}

export function printPublishMonth(dto) {
  return dto.month ? {
    create: `-${insertLeadingZeros(dto.month, 2)}`,
    format: 'MMMM '
  } : {create: '', format: ''};
}

export function printPublishDay(dto) {
  return dto.month && dto.day ? {
    create: `-${insertLeadingZeros(dto.day, 2)}`,
    format: 'DD '
  } : {create: '', format: ''};
}
