import {optionalInsert} from './StringUtils';
import Cookies from 'js-cookie';
import {AVAILABLE, EN} from '../i18n/Lang';
import {store} from '../store';

export function isLocalhost() {
  return window.location.hostname === 'localhost';
}

export function getFirstLevelDomain() {
  return (isLocalhost() ? 'localhost' : window.location.hostname.match(/[^.]*\.[^.]*$/)[0])
    + optionalInsert(window.location.port, `:${window.location.port}`);
}

export function getUpperLevelDomain() {
  if (isLocalhost()) {
    return '';
  }
  const hostname = window.location.hostname;
  const firstDot = hostname.indexOf('.');
  const isSubdomain = hostname.indexOf('.', firstDot + 1);
  return isSubdomain !== -1 ? hostname.substring(0, firstDot) : '';
}

export function getUserDomain(ownerId) {
  return findUserById(ownerId)?.domain;
}

export function findUserById(id) {
  return store.getState().common.users?.find(user => user.id === id) ?? '';
}

export function getBrowserLocale() {
  return navigator.language.match(/^(\w{2}).*/)[1];
}

export function getLocale() {
  const locale = Cookies.get('family_lang') ?? getBrowserLocale();
  return AVAILABLE.find(lang => lang.key === locale) ?? EN;
}

export function isSubdomain() {
  if (isLocalhost()) {
    return false;
  }
  const hostname = window.location.hostname;
  const firstDot = hostname.indexOf('.');
  const isSubdomain = hostname.indexOf('.', firstDot + 1);
  return isSubdomain !== -1;
}
