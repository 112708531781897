import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid/Grid';
import IconButton from '@material-ui/core/IconButton/IconButton';
import Menu from '@material-ui/core/Menu/Menu';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {Language, MenuRounded} from '@material-ui/icons';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import './NavBar.scss';
import {dialogShow} from '../../common/CommonActions';
import {toDomainUrl} from '../../utils/StringUtils';
import {findUserById, isSubdomain} from '../../utils/CommonUtils';
import {changeLanguage} from '../../i18n/I18nActions';
import {AVAILABLE} from '../../i18n/Lang';

const NAV_BAR_TYPE_FIXED = 'NAV_BAR_TYPE_FIXED';
const NAV_BAR_TYPE_BLOCK = 'NAV_BAR_TYPE_BLOCK';
const NAV_BAR_TYPE_DYNAMIC = 'NAV_BAR_TYPE_DYNAMIC';


class NavBar extends Component {

  state = {
    anchorEl: null,
    displayedMenuItems: -1,
    showMenuButton: true,
    isSubDomain: isSubdomain()
  };

  buildRedirectDialog = (dialog, domain, sub = true) => {
    return () => {
      const url = toDomainUrl(domain, '/');
      const hostname = url.length > 5 ? url.match(/:\/\/([\w\d.:]+)\//)[1] : '';
      let domainUrl = <a className='redirect-dialog-url' href={url} target='_self'>{hostname}</a>;
      dialog(this.props.t(sub ? 'redirect.questionSub' : 'redirect.questionPar'), domainUrl, [
        {
          name: this.props.t('redirect.continue'),
          close: true,
          action: () => window.location.href = url
        },
        {
          name: this.props.t('redirect.cancel'),
          close: true
        }
      ]);
    };
  };

  handleLocaleChange = () => {
    const selection = AVAILABLE.find(lang => lang.key !== this.props.locale);
    this.props.changeLocale(selection);
  };

  menuEntries = [
    {
      id: -2,
      type: NAV_BAR_TYPE_FIXED,
      title: 'nav.portal',
      page: null,
      condition: () => this.state.isSubDomain,
      lr: false,
      act: this.buildRedirectDialog(this.props.showDialog, '', false)
    },
    {
      id: -1,
      type: NAV_BAR_TYPE_FIXED,
      title: 'nav.home',
      page: '/',
      condition: () => true,
      lr: false,
      act: null
    },
    {
      id: 1000000,
      type: NAV_BAR_TYPE_FIXED,
      title: 'nav.bio',
      page: '/bio',
      condition: () => this.state.isSubDomain,
      lr: false,
      act: null
    },
    {
      id: 1000001,
      type: NAV_BAR_TYPE_FIXED,
      title: 'nav.about',
      page: '/about',
      condition: () => true,
      lr: false,
      act: null
    },
    {
      id: 1000002,
      type: NAV_BAR_TYPE_BLOCK,
      title: () => <>
        <Language className='nav-bar-icon'/>
        {AVAILABLE.find(lang => lang.key !== this.props.locale).key.toUpperCase()}
      </>,
      page: null,
      condition: () => true,
      lr: false,
      act: () => this.handleLocaleChange()
    }
  ];

  handleShowMenu = event => {
    this.setState({anchorEl: event.currentTarget});
  };

  hideMenu = () => {
    this.setState({anchorEl: null});
  };

  hideMenuAction = action => {
    return () => {
      this.hideMenu();
      if (action) action();
    };
  };

  componentDidMount() {
    this.updateWidth();
    window.addEventListener('resize', this.updateWidth);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const isI18nLoaded = this.props.i18nLoaded;
    if (isI18nLoaded !== prevProps.i18nLoaded) {
      this.updateWidth();
    }

    const isNotSubDomain = !this.state.isSubDomain;
    const someUsersIsHere = this.props.users?.length;
    const isAnyoneNotAddedYet = !this.state.loadedUsers?.every(id => findUserById(id));
    const isLocaleChanged = this.props.locale !== prevProps.locale;
    if (isI18nLoaded && someUsersIsHere && isNotSubDomain && (isAnyoneNotAddedYet || isLocaleChanged)) {
      let rus = this.props.isRu;
      let users = this.props.users.map(user => ({
        id: user.id,
        type: NAV_BAR_TYPE_DYNAMIC,
        title: rus ? user['nameRu'] : user['nameEn'],
        page: null,
        condition: () => true,
        lr: false,
        act: this.buildRedirectDialog(this.props.showDialog, user.domain)
      }));
      this.setState({
        loadedUsers: this.props.users.map(user => user.id),
        users: [...this.menuEntries, ...users].sort((a, b) => a.id - b.id)
      }, () => this.updateWidth());
    }
  }

  updateWidth = () => {
    if (window.innerWidth < 350) {
      this.setState({displayedMenuItems: 0, showMenuButton: true});
      return;
    }
    const containerWidth = document.querySelector('.app-bar-container').clientWidth;
    const calculator = document.querySelector('.app-bar-text-length-calculator');
    const menuButton = document.querySelector('.app-bar-menu-button');
    let width = menuButton ? menuButton.clientWidth : 0;
    let num = 0;
    const show = this.getMenuEntries().filter(entry => entry.lr);
    for (const el in show) {
      if (!show.hasOwnProperty(el)) continue;
      const entry = show[el];
      calculator.innerHTML = entry.type === NAV_BAR_TYPE_FIXED ? this.props.t(entry.title) : 'icon';
      const w = calculator.clientWidth + 40;
      if (w + width < containerWidth) {
        width += w;
        num++;
        continue;
      }
      break;
    }
    const all = this.getMenuEntries().filter(entry => entry.lr).length;
    this.setState({displayedMenuItems: num, showMenuButton: all > num});
  };

  printMenuItem = (isHidden, entry) => {
    const idPrefix = isHidden ? 'b' : '';
    const itemClass = isHidden ? 'app-bar-menu-item-hidden' : 'app-bar-menu-item';
    const clickFcn = isHidden ? this.hideMenuAction(entry.act) : entry.act;
    if (NAV_BAR_TYPE_BLOCK === entry.type) {
      return <MenuItem
        key={idPrefix + entry.id}
        className={itemClass}
        onClick={clickFcn}
      >{entry.title(isHidden)}</MenuItem>;
    }
    const title = NAV_BAR_TYPE_FIXED === entry.type ? this.props.t(entry.title) : entry.title;
    return entry.page
      ? <MenuItem
        key={idPrefix + entry.id}
        className={itemClass}
        component={NavLink}
        onClick={clickFcn}
        to={entry.page}
      >{title}</MenuItem>
      : <MenuItem
        key={idPrefix + entry.id}
        className={itemClass}
        onClick={clickFcn}
      >{title}</MenuItem>;
  };

  getMenuEntries = () => (this.state.isSubDomain ? this.menuEntries : this.state.users) ?? [];

  printMenuItems = isHidden => {
    const showAll = this.state.displayedMenuItems < 0;
    return this.getMenuEntries()
      .map(entry => {
        entry.lr = false;
        return entry;
      })
      .filter(entry => entry.condition())
      .map((entry, index) => {
          entry.lr = true;
          if (showAll || (isHidden && (index >= this.state.displayedMenuItems))) {
            return this.printMenuItem(isHidden, entry);
          }
          if (showAll || (!isHidden && (index < this.state.displayedMenuItems))) {
            return this.printMenuItem(isHidden, entry);
          }
          return '';
        }
      );
  };

  getSiteName = () => {
    if (this.state.isSubDomain && this.props.users?.length) {
      const user = this.props.users[0];
      return this.props.isRu ? user['nameRu'] + ' ' + user['lastRu'] : user['nameEn'] + ' ' + user['lastEn'];
    }
    return this.props.t('name');
  };

  render() {
    const {anchorEl} = this.state;

    return (
      <AppBar className="app-bar-fixed">
        <MenuItem className="app-bar-text-length-calculator invisible"/>
        <Toolbar>
          <Grid item xs={4}>
            <Typography className="app-bar-title" color="inherit" component={NavLink} to="/">
              {this.getSiteName()}
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Grid container justify="flex-end" className="app-bar-container">
              {this.printMenuItems(false)}
              {this.state.showMenuButton ? [
                <IconButton color="inherit"
                            key="app-bar-menu-icon"
                            className="app-bar-menu-button"
                            aria-owns={anchorEl ? 'app-bar-menu-button' : undefined}
                            aria-haspopup="true"
                            onClick={this.handleShowMenu}>
                  <MenuRounded/>
                </IconButton>,
                <Menu id="app-bar-menu-button"
                      key="app-bar-menu-content"
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={this.hideMenu}>
                  {this.printMenuItems(true)}
                </Menu>
              ] : ''}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  }
}

const mapStateToProps = state => {
  return {
    t: state.i18n.translate,
    i18nLoaded: state.i18n.initialized,
    locale: state.i18n.current?.key,
    isRu: state.i18n.isRu,
    isEn: state.i18n.isEn,
    users: state.common.users
  };
};

const mapActionsToProps = dispatch => ({
  showDialog: (title, body, actions) => dispatch(dialogShow(title, body, actions)),
  changeLocale: language => dispatch(changeLanguage(language))
});

export default connect(mapStateToProps, mapActionsToProps)(NavBar);
