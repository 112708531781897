export const BlueDarkBlueTheme = {
  'palette': {
    'common': {'black': '#000', 'white': '#fff'},
    'background': {'paper': '#fff', 'default': '#fafafa'},
    'primary': {
      'light': 'rgba(129, 212, 250, 1)',
      'main': 'rgba(61, 189, 246, 1)',
      'dark': 'rgba(90, 148, 175, 1)',
      'contrastText': '#fff'
    },
    'secondary': {
      'light': 'rgba(99, 123, 254, 1)',
      'main': 'rgba(61, 90, 254, 1)',
      'dark': 'rgba(42, 62, 177, 1)',
      'contrastText': 'rgba(255, 255, 255, 1)'
    },
    'error': {'light': '#e57373', 'main': '#f44336', 'dark': '#d32f2f', 'contrastText': '#fff'},
    'text': {
      'primary': 'rgba(0, 0, 0, 0.87)',
      'secondary': 'rgba(0, 0, 0, 0.54)',
      'disabled': 'rgba(0, 0, 0, 0.38)',
      'hint': 'rgba(0, 0, 0, 0.38)'
    }
  }
};

export const BlueGreenTheme = {
  'palette': {
    'common': {'black': 'rgba(14, 7, 7, 1)', 'white': 'rgb(255, 255, 255)'},
    'background': {'paper': 'rgb(255,255,255)', 'default': 'rgb(255, 255, 255)'},
    'primary': {
      'light': '#88deff',
      'main': '#49bdea',
      'dark': '#1f0f6c',
      'contrastText': '#fff'
    },
    'secondary': {
      'light': 'rgba(129, 233, 122, 1)',
      'main': 'rgba(76, 182, 73, 1)',
      'dark': 'rgb(0,133,25)',
      'contrastText': 'rgba(67, 66, 66, 1)'
    },
    'error': {'light': '#e57373', 'main': '#f44336', 'dark': '#d32f2f', 'contrastText': '#fff'},
    'text': {
      'primary': 'rgba(0, 0, 0, 0.87)',
      'secondary': 'rgba(0, 0, 0, 0.54)',
      'disabled': 'rgba(0, 0, 0, 0.38)',
      'hint': 'rgba(0, 0, 0, 0.38)'
    }
  }
};
