import {parseResponse} from '../utils/ResponseUtils';
import * as Lang from './Lang';
import {changeLocale} from '../services/ConfigService';

export function initLanguage(language) {
  return changeLanguage(language, false);
}

export function changeLanguage(lang, updateBackend = true) {
  return async dispatch => {
    if (Lang.AVAILABLE.indexOf(lang) === -1) {
      return;
    }

    dispatch({type: Lang.LANGUAGE_CHANGE, payload: lang});

    fetch(`/locales/${lang.key}/translation.json`).then(parseResponse).then(handleLoadedLocale(dispatch));

    if (updateBackend) changeLocale(lang.key);
  };
}

function handleLoadedLocale(dispatch) {
  return response => dispatch({type: Lang.LANGUAGE_LOADED, payload: {keys: response}});
}

export function setLoadedLocale(keys, requested) {
  return async dispatch => {
    dispatch({type: Lang.LANGUAGE_LOADED, payload: {keys, requested}});
  };
}
