import AppBar from '@material-ui/core/es/AppBar/AppBar';
import Toolbar from '@material-ui/core/es/Toolbar/Toolbar';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import './App.scss';
import CommonDialog from './common/CommonDialog';
import NavBar from './components/NavBar/NavBar';
import Route from './routes/Route';
import {initLanguage, setLoadedLocale} from './i18n/I18nActions';
import {getLocale} from './utils/CommonUtils';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core';
import {BlueGreenTheme} from './utils/Theme';
import {requestConfig, setConfig} from './common/CommonActions';
import {sendError} from './services/ErrorService';

class App extends Component {

  state = {
    loading: true
  };

  componentDidMount() {
    window.addEventListener('error', this.onErrorHandler);
    this.configureLocale();
    this.initConfig();
    this.removePreloader();
  }

  onErrorHandler = (args) => {
    const {message, error} = args;
    const stack = error ? error.stack.replace(/(^"\s*|[\r\n]\s*|\s*"$)/g, ' ') : '';
    sendError(`${message}; stack: ${stack}`);
  };

  initConfig = () => {
    if (window.config) {
      this.props.setConfig(window.config);
    } else {
      this.props.loadConfig();
    }
  };

  configureLocale = () => {
    const locale = getLocale();
    if (window.translation) {
      const keys = JSON.parse(window.translation);
      if (keys.locale === locale.key) {
        this.props.setLanguage(keys, locale);
      } else {
        this.props.initLanguage(locale);
      }
    } else {
      this.props.initLanguage(locale);
    }
  };

  removePreloader = () => {
    const pl = document.getElementById('pl');
    const ps = document.getElementById('ps');
    if (pl) {
      pl.remove();
      ps.remove();
      this.setState({loading: false});
    }
  };

  render() {
    let theme = createMuiTheme(BlueGreenTheme);

    return (
      <div className="family">
        <MuiThemeProvider theme={theme}>
          <AppBar className="invisible" position="relative"><Toolbar/></AppBar>
          <NavBar/>
          <Route/>
          {this.props.dialogAdd ? <CommonDialog/> : ''}
        </MuiThemeProvider>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    dialogAdd: state.common.dialogOpen
  };
};

const mapActionsToProps = dispatch => ({
  initLanguage: language => dispatch(initLanguage(language)),
  setLanguage: (translation, language) => dispatch(setLoadedLocale(translation, language)),
  loadConfig: () => dispatch(requestConfig()),
  setConfig: config => dispatch(setConfig(config))
});

export default connect(mapStateToProps, mapActionsToProps)(App);
