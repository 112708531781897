import {
  COMMON_CONFIG_LOAD,
  COMMON_CONFIG_SET,
  COMMON_DIALOG_CLOSE,
  COMMON_DIALOG_OPEN,
  dialogDefaultAction
} from './CommonConstants';
import {loadConfig} from '../services/ConfigService';

export function dialogSimpleShow(content) {
  return async dispatch => {
    dispatch({
      type: COMMON_DIALOG_OPEN,
      payload: {title: null, content, actions: [dialogDefaultAction]},
      config: {}
    });
  };
}

export function dialogShow(title, content, actions = [dialogDefaultAction], config = {}) {
  return async dispatch => {
    dispatch({type: COMMON_DIALOG_OPEN, payload: {title, content, actions, config}});
  };
}

export function dialogClose() {
  return async (dispatch) => {
    dispatch({type: COMMON_DIALOG_CLOSE});
  };
}

export function requestConfig() {
  return async dispatch => {

    loadConfig().then(handleLoadedConfig(dispatch));

    dispatch({type: COMMON_CONFIG_LOAD, payload: {}});
  };
}

function handleLoadedConfig(dispatch) {
  return response => dispatch({type: COMMON_CONFIG_SET, payload: response.data});
}

export function setConfig(config) {
  return async dispatch => {
    dispatch({type: COMMON_CONFIG_SET, payload: config});
  };
}
